import {
  email,
  emailConfirm,
  phoneNumber,
  phoneType,
} from '@mgo/core/src/validations/person';

import {
  oldCity,
  oldState,
  oldZipCode,
  oldUrbanizationName,
  newCity,
  newState,
  newZipCode,
  newUrbanizationName,
} from '@mgo/core/src/validations/address';

import {
  oldPoBoxNumber,
  oldStreetAddress,
  newPoBoxNumber,
  newPrivateMailbox,
  newStreetAddress,
  startDate,
  endDate,
} from '@mgo/core/src/validations/coac';

import {
  moverType,
  forwardType,
} from '@mgo/core/src/validations/coa';

export default {
  email,
  emailConfirm,
  phoneNumber,
  phoneType,
  oldStreetAddress,
  oldCity,
  oldState,
  oldZipCode,
  oldPoBoxNumber,
  oldUrbanizationName,
  newStreetAddress,
  newCity,
  newState,
  newZipCode,
  newPoBoxNumber,
  newPrivateMailbox,
  newUrbanizationName,
  startDate,
  endDate,
  moverType,
  forwardType,
};
