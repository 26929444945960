import { track } from '../../analytics/events';

export const desktopAnalytics = () => ({
  // FORM EVENTS
  form: {
    submitted: () => track('formSubmitted', {
      formType: 'EDIT',
      formName: 'EDIT',
    }),
  },
  // CLICK EVENTS
  click: {
    cancelMyOrder: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Cancel My Order',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    back: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Back',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    saveChanges: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Save Changes',
      },
      actionOutcome: 'SUBMIT',
    }),
  },
});

export const mobileAnalytics = () => ({
  // CLICK EVENTS
  click: {
    cancelMyOrder: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Cancel My Order',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    back: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Back',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    saveChanges: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Save Changes',
      },
      actionOutcome: 'SUBMIT',
    }),
  },
});
