import {
  format as f,
  parseISO,
  isPast,
} from 'date-fns';
import { getAccessOrigin } from '../../utils/destinationManager';
import { identify } from '../../analytics/events';

export default {
  data() {
    return {
      loading: true,
      coa: {},
      allowEdit: true,
      militaryAddress: false,
      emfAdvertDismissed: false,
      apiException: this.$store.get('apiException') || '',
      apiMessage: this.$store.get('apiMessage') || '',
      activationRequired: false,
      activationSuccess: false,
      activationFailed: false,
    };
  },
  beforeUnmount() {
    this.$store.dispatch('RESET_ERROR');
  },
  computed: {
    flagEditable() {
      return !!this.coa?.editable;
    },
    flagEmfEnabled() {
      return this.$store.get('config@coacExtendedFwdEnabled') || false;
    },
    flagAccessHandoffEnabled() {
      return this.$store.get('config@accessHandoffEnabled') || false;
    },
    showEmfAdvert() {
      return this.savedChanges
        && this.coa?.extInfo
        && this.coa?.extInfo?.eligible
        && !this.emfAdvertDismissed;
    },
    showVoterReg() {
      // Save was successful
      // IND or FAM Permanent filer
      // New address state is not restricted state ('ND', 'NH', 'WY')
      return this.flagAccessHandoffEnabled
        && this.savedChanges
        && this.coa.moverType !== 'BUSINESS'
        && this.coa.forwardType === 'PERMANENT'
        && ['ND', 'NH', 'WY'].indexOf(this.coa.newAddress.state) < 0;
    },
    editCanceled() {
      return JSON.parse(this.$route?.query?.editCanceled || false);
    },
    startDateEditable() {
      if (!this.coa.startDate) return false;
      return !isPast(parseISO(this.coa.startDate));
    },
    endDateEditable() {
      if (!this.coa.endDate) return false;
      return !isPast(parseISO(this.coa.endDate));
    },
    coaNotCanceled() {
      return JSON.parse(this.$route?.query?.notCanceled || false);
    },
    savedChanges() {
      return JSON.parse(this.$route?.query?.savedChanges || false);
    },
    emfSaved() {
      return JSON.parse(this.$route?.query?.extensionSuccess || false);
    },
    cmraError() {
      return JSON.parse(this.$route?.query?.cmra || false);
    },
    emfProcessingError() {
      return JSON.parse(this.$route?.query?.emfProcessingFailed || false);
    },
    amsCorrectedNewZip() {
      return JSON.parse(this.$route?.query?.amsCorrectedNewZip || false);
    },
    allowCoaCancel() {
      return !this.coa?.processing
        && !this.coa?.eodRunning;
    },
    coaUnEditableAndProcessing() {
      return this.coa?.processing
        && !this.coa?.editable;
    },
  },
  async mounted() {
    this.coa = await this.$coac.search();
    this.markAsReadOnly(this);
    this.coa.medUpdated = (sessionStorage.getItem('medUpdated') !== undefined) ? JSON.parse(sessionStorage.getItem('medUpdated')) : false;
    this.loading = false;

    if (!this.coa?.processing && !this.coa?.editable) {
      this.showActivationModal(this.coa?.locked);
    }
    identify(sessionStorage.getItem('coacUuid'));
  },
  methods: {
    async markAsReadOnly({
      coa: {
        forwardType, expired, oldAddress, newAddress,
      },
    }) {
      if (forwardType === 'TEMPORARY' && expired) {
        return Object.assign(this, { allowEdit: false });
      }
      const { military: newAddressMilitary } = newAddress;
      const { military: oldAddressMilitary } = oldAddress;
      if (oldAddressMilitary || newAddressMilitary) {
        return Object.assign(this, { allowEdit: false, militaryAddress: true });
      }
      return null;
    },
    zip9Format: (value) => value.replace(/(\d{5})(\d{4})/g, '$1-$2') || '',
    phoneFormat: (value) => (value
      ? value.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3')
      : ''),
    dateFormat: (value, format = 'EEEE, LLLL dd, yyyy') => f(parseISO(value), format),
    getHandoffUrl(code) {
      if (!code) return '';
      return `${getAccessOrigin(window.location.origin)}/welcome?access_code=${code}`;
    },
    async handoff() {
      const { code } = await this.$coac.handoffCodeGet();
      const url = this.getHandoffUrl(code);

      window.location.href = url;
    },
    showActivationModal(activationFailed) {
      this.activationFailed = activationFailed;
      this.activationRequired = true;
    },
    async refreshCoa() {
      this.loading = true;
      this.coa = await this.$coac.search();
      this.activationSuccess = true;
      this.loading = false;
    },
  },
};
