/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import string from './stringValidator';

export const oldStreetAddress = string()
  .label('Street Address')
  .requestFieldValidation('oldStreetAddress')
  .required('This field cannot be empty.')
  .test(
    'AmsInvalidAddressException',
    'We cannot find the address you\'re moving from in our records. Please modify your address and then click "Next."',
    function () {
      if (this.options.context?.oldAddressError === 'AmsInvalidAddressException') return false;
      return true;
    },
  )
  .test(
    'PrimaryNumberMissingException',
    'Invalid address.  Enter a street number.',
    function () {
      if (this.options.context?.oldAddressError === 'PrimaryNumberMissingException') return false;
      return true;
    },
  )
  .test(
    'C777AddressException',
    'Invalid address.  Enter PO Box number for this address.',
    function () {
      if (this.options.context?.oldAddressError === 'C777AddressException') return false;
      return true;
    },
  )
  .test(
    'MilitaryAddressException',
    'This address is not acceptable for the move type of Business.',
    function () {
      if (this.options.context?.oldAddressError === 'MilitaryAddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryPoBoxException',
    'Invalid PO Box number. Enter valid PO box number.',
    function () {
      if (this.options.context?.oldAddressError === 'InvalidPrimaryPoBoxException'
        && !this.options.context?.showOldPoBox) return false;
      return true;
    },
  )
  .test(
    'AmsMultipleAddressException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.oldAddressError === 'AmsMultipleAddressException') return false;
      return true;
    },
  )
  .test(
    'TooManyComponentsException',
    'The "Street Address" field should not include the city, state, or ZIP. Remove the city, state or ZIP from this field.',
    function () {
      if (this.options.context?.oldAddressError === 'TooManyComponentsException') return false;
      return true;
    },
  )
  .test(
    'UnneededSecondaryInformationException',
    'Our records indicate a unit or apartment number is not needed for this address. Remove the unit or apartment number or enter a valid address.',
    function () {
      if (this.options.context?.oldAddressError === 'UnneededSecondaryInformationException') return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryInformationException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.oldAddressError === 'InvalidPrimaryInformationException' && !this.options.context.showOldPoBox) return false;
      return true;
    },
  )
  .test(
    'UndeliverableAddressException',
    'Address not valid, enter a valid address.',
    function () {
      if (this.options.context?.oldAddressError === 'UndeliverableAddressException') return false;
      return true;
    },
  )
  .test(
    'OldAndNewAddressMatchException',
    'The old and new address match.  Enter a different old or new address.',
    function () {
      if (this.options.context?.oldAddressError === 'OldAndNewAddressMatchException') return false;
      return true;
    },
  )
  .test(
    'AmsInvalidStreetAddressException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.oldAddressError === 'AmsInvalidStreetAddressException' && !this.options.context.showOldPoBox) return false;
      return true;
    },
  )
  .test(
    'CCR1CmraAddressException',
    'Our records indicate the unit or apartment number is incorrect. Please correct the unit or apartment number in the "Street Address" field and add the Private Mailbox in the “Private Mailbox” field and click "Next." USPS will standardize your address when the “Next” button is clicked',
    function () {
      if (this.options.context?.oldAddressError === 'CCR1CmraAddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidSecondaryInformationException',
    'Invalid Address. Enter unit or apartment number in the street name field.',
    function () {
      if (this.options.context?.oldAddressError === 'InvalidSecondaryInformationException') return false;
      return true;
    },
  )
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    function (value) {
      if (value?.toLowerCase().indexOf('po box') !== -1 && !value?.match(/^po box [a-zA-Z0-9]{1,10}$/i)) return false;
      return true;
    },
  )
  .test(
    'OldPoBoxRequiredException',
    'Invalid PO Box number. Enter valid PO box number.',
    function (value) {
      if (this.options.context?.oldAddressError === 'OldPoBoxRequiredException' && value.toLowerCase().indexOf('po box') >= 0) return false;
      return true;
    },
  );

export const oldCity = string()
  .label('City')
  .requestFieldValidation('oldCity')
  .required('This field cannot be empty.');

export const oldState = string()
  .label('State')
  .requestFieldValidation('oldState')
  .required('Please select a state or territory.');

export const oldZipCode = string()
  .label('Zip Code')
  .requestFieldValidation('oldZipCode')
  .min(5, 'The ZIP Code™ should be 5 digits long.')
  .max(5, 'The ZIP Code™ should be 5 digits long.')
  .test(
    'AmsInvalidZipException',
    'Invalid ZIP Code™.',
    function () {
      if (this.options.context?.oldAddressError === 'AmsInvalidZipException') return false;
      return true;
    },
  )
  .test(
    'Zip9NotEligibleException',
    'This ZIP Code™ is listed as restricted and may not be used for online Change-of-Address requests at this time.',
    function () {
      if (this.options.context?.oldAddressError === 'Zip9NotEligibleException') return false;
      return true;
    },
  )
  .required('This field cannot be empty.');

export const oldPoBoxNumber = string()
  .label('PO Box Number')
  .nullable()
  .requestFieldValidation('oldPoBoxNumber')
  .test(
    'R777Exception',
    'A PO Box is required to proceed.',
    function () {
      if (this.options.context.oldAddressError === 'R777AddressException'
        && this.options.context.showOldPoBox) return false;
      return true;
    },
  )
  .test(
    'OldPoBoxRequiredException',
    'A PO Box is required to proceed.',
    function () {
      if (this.options.context.oldAddressError === 'OldPoBoxRequiredException'
        && this.options.context.showOldPoBox) return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryInformationException',
    'Address not found, enter a valid address.',
    function () {
      if (['AmsInvalidStreetAddressException', 'InvalidPrimaryInformationException'].includes(this.options.context?.oldAddressError) && this.options.context.showOldPoBox) return false;
      return true;
    },
  )

  .test(
    'InvalidPrimaryPoBoxException',
    'Invalid PO Box number. Enter valid PO box number.',
    function () {
      if (this.options.context?.oldAddressError === 'InvalidPrimaryPoBoxException'
        && this.options.context?.showOldPoBox) return false;
      return true;
    },
  )
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    function (val) {
      if (!val) return true;
      return val.match(/^po box [a-zA-Z0-9]{1,10}$/i);
    },
  );

export const oldUrbanizationName = string()
  .label('Urbanization Name (Puerto Rico only')
  .requestFieldValidation('oldUrbanizationName')
  .test(
    'allowed characters',
    'The Urbanization name field allows only letters, numbers, and spaces.',
    function (val) {
      if (!val) return true;
      return val.match(/^[a-zA-Z0-9 ]*$/i);
    },
  );

export const newStreetAddress = string()
  .label('Street Address')
  .requestFieldValidation('newStreetAddress')
  .test(
    'NewAddressMatchesAms',
    'We cannot find the address you\'re moving to in our records. Please verify the address for accuracy and click "Edit" then "Next" to make corrections.',
    function () {
      if (this.options.context?.newAddressError === 'NewAddressMatchedAms') return false;
      return true;
    },
  )
  .test(
    'NewAddressDroppedSecondaryNumber',
    'According to our records, there is no unit or apartment number associated with this address. Please verify the address for accuracy and click "Edit" then "Next" to make corrections.',
    function () {
      if (this.options.context?.newAddressError === 'NewAddressDroppedSecondaryNumber') return false;
      return true;
    },
  )
  .test(
    'NewPoBoxRequiredException',
    'Invalid PO Box number. Enter valid PO box number.',
    function () {
      if (this.options.context?.newAddressError === 'NewPoBoxRequiredException'
          && !this.options.context?.showNewPoBox) return false;
      return true;
    },
  )
  .test(
    'AmsInvalidAddressException',
    'We cannot find the address you\'re moving to in our records. Please modify your address and then click "Next."',
    function () {
      if (this.options.context?.newAddressError === 'AmsInvalidAddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidSecondaryInformationException',
    'Invalid Address. Enter unit or apartment number in the street name field.',
    function () {
      if (this.options.context?.newAddressError === 'InvalidSecondaryInformationException') return false;
      return true;
    },
  )
  .test(
    'AmsMultipleAddressException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.newAddressError === 'AmsMultipleAddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidSecondaryInformationZip11Exception',
    'A unit or apartment number is either missing, incomplete or invalid according to our records. Please add it in with the street name in the "Street" field (USPS will standardize your address upon submission) and then click "Next."',
    function () {
      if (this.options.context?.newAddressError === 'InvalidSecondaryInformationZip11Exception') return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryPoBoxException',
    'Invalid PO Box number. Enter valid PO box number.',
    function () {
      if (this.options.context?.newPoBoxNumber === 'InvalidPrimaryPoBoxException' || this.options.context?.newAddressError === 'InvalidPrimaryPoBoxException') return false;
      return true;
    },
  )
  .test(
    'NewAddressFieldFullAddressException',
    'The "Street Address" field should not include the city, state, or ZIP. Remove the city, state or ZIP from this field.',
    function () {
      if (this.options.context?.newAddressError === 'NewAddressFieldFullAddressException') return false;
      return true;
    },
  )
  .test(
    'C777AddressException',
    'Invalid address.  Enter PO Box number for this address.',
    function () {
      if (this.options.context?.newAddressError === 'C777AddressException') return false;
      return true;
    },
  )
  .test(
    'UndeliverableAddressException',
    'Address not valid, enter a valid address.',
    function () {
      if (this.options.context?.newAddressError === 'UndeliverableAddressException') return false;
      return true;
    },
  )
  .test(
    'AmsInvalidStreetAddressException',
    'We cannot find the street address that you entered in our records. Please check the spelling of all address information to ensure it is accurate. If you are sure the existing address is correct, please contact your local Post Office to validate your mailing address.',
    function () {
      if (this.options.context?.newAddressError === 'AmsInvalidStreetAddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryInformationException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.newAddressError === 'InvalidPrimaryInformationException' && !this.options.context.showNewPoBox) return false;
      return true;
    },
  )
  .test(
    'OldAndNewAddressMatchException',
    'The old and new address match.  Enter a different old or new address.',
    function () {
      if (this.options.context?.newAddressError === 'OldAndNewAddressMatchException') return false;
      return true;
    },
  )
  .test(
    'UnneededSecondaryInformationException',
    'Our records indicate a unit or apartment number is not needed for this address. Remove the unit or apartment number or enter a valid address.',
    function () {
      if (this.options.context?.newAddressError === 'UnneededSecondaryInformationException') return false;
      return true;
    },
  )
  .test(
    'CCNotZip11Exception',
    'According to our records, there is no unit or apartment number associated with this address. Please verify the address for accuracy before continuing, and then click "Next."',
    function () {
      if (this.options.context?.newAddressError === 'CCNotZip11Exception') return false;
      return true;
    },
  )
  .test(
    'TooManyComponentsException',
    'The street address field cannot include the city, state or ZIP.',
    function () {
      if (this.options.context?.newAddressError === 'TooManyComponentsException') return false;
      return true;
    },
  )
  .test(
    'PrimaryNumberMissingException',
    'Invalid address.  Enter a street number.',
    function () {
      if (this.options.context?.newAddressError === 'PrimaryNumberMissingException') return false;
      return true;
    },
  )
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    function (val) {
      if (val?.toLowerCase().indexOf('po box') !== -1 && !val?.match(/^po box [a-zA-Z0-9]{1,10}$/i)) return false;
      return true;
    },
  )
  .test(
    'MilitaryAddressException',
    'This address is not acceptable for the move type of Business.',
    function () {
      if (this.options.context?.newAddressError === 'MilitaryAddressException') return false;
      return true;
    },
  )
  .required('This field cannot be empty.');

export const newCity = string()
  .label('City')
  .requestFieldValidation('newCity')
  .required('This field cannot be empty.');

export const newState = string()
  .label('State')
  .requestFieldValidation('newState')
  .required('Please select a state or territory.');

export const newZipCode = string()
  .label('Zip Code')
  .requestFieldValidation('newZipCode')
  .min(5, 'The ZIP Code™ should be 5 digits long.')
  .max(5, 'The ZIP Code™ should be 5 digits long.')
  .required('This field cannot be empty.');

export const newPrivateMailbox = string()
  .label('Private Mailbox')
  .nullable()
  .requestFieldValidation('newPrivateMailbox')
  .test(
    'CCR1CmraAddressException',
    'A private mailbox number is required for a commercial mail receiving agency.  Enter the private mailbox number in the "Private Mailbox" field.',
    function () {
      if (this.options.context?.newAddressError === 'CCR1CmraAddressException') return false;
      return true;
    },
  )
  .test(
    'CmraAddressException',
    'A private mailbox number is required for a commercial mail receiving agency.  Enter the private mailbox number in the "Private Mailbox" field.',
    function () {
      if (this.options.context?.newAddressError === 'CmraAddressException') return false;
      return true;
    },
  )
  .test(
    'allowed characters',
    'We allow the following characters: PMB, space, -, # or numbers. Please try again using the following format: PMB number or #number.',
    function (val) {
      if (!val) return true;
      if (val.match(/(#)(?:\d+)/)) return true;

      const v = val.toLowerCase().split(' ');
      return v.length === 2 && (v[0] === 'pmb') && v[1].length && v[1].match(/^[a-zA-Z0-9 #-]*$/);
    },
  );

export const newPoBoxNumber = string()
  .label('PO Box Number')
  .nullable()
  .requestFieldValidation('newPoBoxNumber')
  .test(
    'NewPoBoxRequiredException',
    'A PO Box is required to proceed.',
    function () {
      if (this.options.context?.newAddressError === 'NewPoBoxRequiredException') return false;
      return true;
    },
  )
  .test(
    'R777AddressException',
    'A PO Box is required to proceed.',
    function () {
      if (this.options.context?.newAddressError === 'R777AddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryInformationException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.newAddressError === 'InvalidPrimaryInformationException' && this.options.context.showNewPoBox) return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryPoBoxException',
    'Invalid PO Box number. Enter valid PO box number.',
    function () {
      if (this.options.context?.newPoBoxNumber === 'InvalidPrimaryPoBoxException'
      || this.options.context?.newAddressError === 'InvalidPrimaryPoBoxException') return false;
      return true;
    },
  )
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    function (val) {
      if (!val) return true;
      return val.match(/^po box [a-zA-Z0-9]{1,10}$/i);
    },
  );

export const newUrbanizationName = string()
  .label('Urbanization Name (Puerto Rico only')
  .requestFieldValidation('newUrbanizationName')
  .test(
    'allowed characters',
    'The Urbanization name field allows only letters, numbers, and spaces.',
    function (val) {
      if (!val) return true;
      return val.match(/^[a-zA-Z0-9 ]*$/i);
    },
  );

export const billingStreetAddress = string()
  .label('Street Address')
  .requestFieldValidation('billingStreetAddress')
  .test(
    'WatchlistException',
    'We are unable to proceed with this order using the billing address entered, select the new or old address as the billing address.',
    function () {
      if (this.options.context?.billingAddressError === 'WatchlistException') return false;
      return true;
    },
  )
  .test(
    'AmsInvalidAddressException',
    'We cannot find the address you\'re moving to in our records. Please modify your address and then click "Next."',
    function () {
      if (this.options.context?.billingAddressError === 'AmsInvalidAddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidSecondaryInformationException',
    'Invalid Address. Enter unit or apartment number in the street name field.',
    function () {
      if (this.options.context?.billingAddressError === 'InvalidSecondaryInformationException') return false;
      return true;
    },
  )
  .test(
    'VacantAddressException',
    'This address is a vacant address and cannot be used for billing purposes. Please use the old or new address or provide an alternate address to be used for billing purposes.',
    function () {
      if (this.options.context?.billingAddressError === 'VacantAddressException') return false;
      return true;
    },
  )
  .test(
    'NostatAddressException',
    'This address is not serviced by USPS and cannot be used for billing purposes.  Please use the old or new address or provide an alternate address to be used for billing purposes.',
    function () {
      if (this.options.context?.billingAddressError === 'NostatAddressException') return false;
      return true;
    },
  )
  .test(
    'CmraBBAddressException',
    'This address is a Commercial Mail Receiving Agency and cannot be used for billing purposes.  Please use the old or new address or provide an alternate address to be used for billing purposes.',
    function () {
      if (this.options.context?.billingAddressError === 'CmraBBAddressException') return false;
      return true;
    },
  )
  .test(
    'R777BBAddressException',
    'This address does not receive USPS delivery and cannot be used for billing purposes.  Please use the old or new address or provide an alternate address to be used for billing purposes.',
    function () {
      if (this.options.context?.billingAddressError === 'R777BBAddressException') return false;
      return true;
    },
  )
  .test(
    'New Street Address InvalidPrimaryPoBoxException',
    'Invalid PO Box number. Enter valid PO box number.',
    function () {
      if (this.options.context?.billingPoBoxNumber === 'InvalidPrimaryPoBoxException' || this.options.context?.billingAddressError === 'InvalidPrimaryPoBoxException') return false;
      return true;
    },
  )
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    function (val) {
      if (val?.toLowerCase().indexOf('po box') !== -1 && !val?.match(/^po box [a-zA-Z0-9]{1,10}$/i)) return false;
      return true;
    },
  )
  .test(
    'C777AddressException',
    'Invalid address.  Enter PO Box number for this address.',
    function () {
      if (this.options.context?.billingAddressError === 'C777AddressException') return false;
      return true;
    },
  )
  .test(
    'UndeliverableAddressException',
    'Address not valid, enter a valid address.',
    function () {
      if (this.options.context?.billingAddressError === 'UndeliverableAddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidStreetAddressException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.billingAddressError === 'InvalidStreetAddressException' && !this.options.context.showBillingPoBox) return false;
      return true;
    },
  )
  .test(
    'AmsInvalidStreetAddressException',
    'We cannot find the street address that you entered in our records. Please check the spelling of all address information to ensure it is accurate. If you are sure the existing address is correct, please contact your local Post Office to validate your mailing address.',
    function () {
      if (this.options.context?.billingAddressError === 'AmsInvalidStreetAddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryInformationException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.billingAddressError === 'InvalidPrimaryInformationException') return false;
      return true;
    },
  )
  .test(
    'UnneededSecondaryInformationException',
    'Our records indicate a unit or apartment number is not needed for this address. Remove the unit or apartment number or enter a valid address.',
    function () {
      if (this.options.context?.billingAddressError === 'UnneededSecondaryInformationException') return false;
      return true;
    },
  )
  .test(
    'CCNotZip11Exception',
    'According to our records, there is no unit or apartment number associated with this address. Please verify the address for accuracy before continuing, and then click "Next."',
    function () {
      if (this.options.context?.billingAddressError === 'CCNotZip11Exception') return false;
      return true;
    },
  )
  .test(
    'TooManyComponentsException',
    'The "Street Address" field should not include the city, state, or ZIP. Remove the city, state or ZIP from this field.',
    function () {
      if (this.options.context?.billingAddressError === 'TooManyComponentsException') return false;
      return true;
    },
  )
  .test(
    'PrimaryNumberMissingException',
    'Invalid address.  Enter a street number.',
    function () {
      if (this.options.context?.billingAddressError === 'PrimaryNumberMissingException') return false;
      return true;
    },
  )
  .test(
    'MilitaryAddressException',
    'This address is not acceptable for the move type of Business.',
    function () {
      if (this.options.context?.billingAddressError === 'MilitaryAddressException') return false;
      return true;
    },
  )
  .test(
    'NonBusinessBillingException',
    'This address is not a business address and cannot be used for billing purposes.  Please use the old or new address or provide an alternate address to be used for billing purposes.',
    function () {
      if (this.options.context?.billingAddressError === 'NonBusinessBillingException') return false;
      return true;
    },
  )
  .test(
    'UniqueZipException',
    'We are unable to process a Change-of-Address for a unique ZIP Code™ online.  For more information on mail forwarding regulations, contact your local Postmaster.',
    function () {
      if (this.options.context?.billingAddressError === 'UniqueZipException') return false;
      return true;
    },
  )
  .required('This field cannot be empty.');

export const billingCity = string()
  .label('City')
  .requestFieldValidation('billingCity')
  .required('This field cannot be empty.');

export const billingState = string()
  .label('State')
  .requestFieldValidation('billingState')
  .required('Please select a state or territory.');

export const billingZipCode = string()
  .label('Zip Code')
  .requestFieldValidation('billingZipCode')
  .min(5, 'The ZIP Code™ should be 5 digits long.')
  .max(5, 'The ZIP Code™ should be 5 digits long.')
  .required('This field cannot be empty.');

export const billingPrivateMailbox = string()
  .label('Private Mailbox')
  .nullable()
  .requestFieldValidation('billingPrivateMailbox')
  .test(
    'CmraAddressException',
    'A private mailbox number is required for a commercial mail receiving agency.  Enter the private mailbox number in the "Private Mailbox" field.',
    function () {
      if (this.options.context?.billingAddressError === 'CmraAddressException') return false;
      return true;
    },
  )
  .test(
    'CCR1CmraAddressException',
    'A private mailbox number is required for a commercial mail receiving agency.  Enter the private mailbox number in the "Private Mailbox" field.',
    function () {
      if (this.options.context?.billingAddressError === 'CCR1CmraAddressException') return false;
      return true;
    },
  )
  .test(
    'allowed characters',
    'We allow the following characters: PMB, space, -, # or numbers. Please try again using the following format: PMB number or #number.',
    function (val) {
      if (!val) return true;
      if (val.match(/(#)(?:\d+)/)) return true;

      const v = val.toLowerCase().split(' ');
      return v.length === 2 && (v[0] === 'pmb') && v[1].length && v[1].match(/^[a-zA-Z0-9 #-]*$/);
    },
  );

export const billingPoBoxNumber = string()
  .label('PO Box Number')
  .nullable()
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    (val) => {
      if (!val) return true;
      return val.match(/^po box [a-zA-Z0-9]{1,10}$/i);
    },
  )
  .test(
    'ThirdPoBoxRequiredException',
    'A PO Box is required to proceed.',
    function () {
      if (this.options.context?.billingAddressError === 'ThirdPoBoxRequiredException'
        && this.options.context.showBillingPoBox) return false;
      return true;
    },
  )
  .test(
    'InvalidStreetAddressException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.billingAddressError === 'InvalidStreetAddressException'
      && this.options.context.showBillingPoBox) return false;
      return true;
    },
  )
  .required('A PO Box is required to proceed.');

export const billingUrbanizationName = string()
  .label('Urbanization Name (Puerto Rico only')
  .requestFieldValidation('billingUrbanizationName')
  .test(
    'allowed characters',
    'The Urbanization name field allows only letters, numbers, and spaces.',
    function (val) {
      if (!val) return true;
      return val.match(/^[a-zA-Z0-9 ]*$/i);
    },
  );
