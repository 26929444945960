/* eslint-disable prefer-arrow-callback */
/* eslint-disable max-len, func-names, no-template-curly-in-string  */
import {
  isBefore,
  isSameDay,
  parseISO,
  startOfDay,
  subYears,
} from 'date-fns';
import { boolean } from 'yup';
import string from './stringValidator';
import { phoneNumberValidator } from './utils';

const today = startOfDay(new Date());

export const firstName = string()
  .label('First Name')
  .nullable()
  .requestFieldValidation('firstName')
  .max(32, 'The First Name field only allows ${max} characters. Please shorten and select next.')
  .test(
    'alphaOnly',
    'The First Name field allows only letters. Please remove all other characters from this field.',
    function (value) {
      return /^[a-zA-Z]+$/gi.test(value);
    },
  )
  .required('Please enter your first name.');

export const middleName = string()
  .label('Middle Name or Initial')
  .requestFieldValidation('middleName')
  .max(32, 'The Middle Name field only allows ${max} characters. Please shorten and select next.')
  .test(
    'alphaOnly',
    'The Middle Name or Initial field allows only letters. Please remove all other characters from this field.',
    function (value) {
      if (!value) return true;
      return /^[a-zA-Z]+$/gi.test(value);
    },
  );

export const lastName = string()
  .label('Last Name')
  .nullable()
  .requestFieldValidation('lastName')
  .test(
    'alphaHyphen',
    'The Last Name field allows only letters and the following characters ( - ) and must begin and end with a letter.',
    function (value) {
      return /^[a-zA-Z]+(-[a-zA-Z]+)*$/gi.test(value);
    },
  )
  .required('Please enter your last name.')
  .max(40, 'The Last Name only allows ${max} characters. Please shorten and select next.');

export const email = string()
  .label('Email')
  .nullable()
  .test(
    'isGovernmentEmail',
    'The email address you have entered is not valid. Please use your .mil, .gov or .edu email address.',
    function (value) {
      if (!value) return false;

      const { oldState, isProduction } = this.options.context;
      if (!oldState) return true;

      const militaryStateOptions = ['AA', 'AE', 'AP'];
      const isMilitary = militaryStateOptions.indexOf(oldState) >= 0;
      if (!isMilitary) return true;

      const [topLevelDomain] = value.split('.').reverse();
      const isMymoveEmail = value.toLowerCase().indexOf('@mymove.com') >= 0;
      if (!isProduction && isMymoveEmail) return true;

      return [
        'gov',
        'edu',
        'mil',
      ].indexOf(topLevelDomain.toLowerCase()) >= 0;
    },
  )
  .required('Please enter a valid email address.');

export const emailConfirm = string()
  .test(
    'sameAsEmail',
    'Emails do not match.',
    function (value) {
      return value && value.toLowerCase() === this.options.context.email.toLowerCase();
    },
  )
  .required('Emails do not match.');

export const phoneNumber = string()
  .label('Phone Number')
  .transform((value) => (!value ? '' : value))
  .min(10, 'Please enter a phone number.')
  .max(10, 'Please enter a phone number.')
  .test(
    'validPhoneNumber',
    'Please enter a valid phone number.',
    (value) => (!value ? true : phoneNumberValidator(value)),
  )
  .required('Please enter a phone number.');

export const phoneType = string()
  .label('Phone Type')
  .required();

export const dateOfBirth = string()
  .label('Date of Birth')
  .test(
    'dob13',
    'Sorry, your online Change-of-Address request cannot be processed. Please check your information and try again.',
    function (value) {
      return isSameDay(parseISO(value), subYears(today, 13)) || isBefore(parseISO(value), subYears(today, 13));
    },
  )
  .required('Please enter your Date Of Birth.');

export const dobVerified = boolean()
  .label('Date of Birth Verification')
  .oneOf([true], 'You must check this box before you press the Next button.');

export const recaptchaToken = string()
  .label('Recaptcha')
  .required('You must complete this step to proceed');

export const recaptchaChallengeToken = string()
  .label('Recaptcha')
  .required('You must complete this step to proceed');
