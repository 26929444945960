/* eslint-disable func-names */
import { addMethod, string } from 'yup';

function requestValidationHandler(fieldName) {
  return this.test(
    'fieldRequestValidator',
    'fieldRequestValidator',
    function (value) {
      let violation;
      if (fieldName.toLowerCase() === 'aliases') {
        if (value) {
          violation = this.options
          ?.context
          ?.fieldErrors
          ?.find(({ field, message }) => field.toLowerCase() === 'aliases' && message.toLowerCase().indexOf(`[${value.toLowerCase()}]`) >= 0);
        }
      } else {
        violation = this.options
          ?.context
          ?.fieldErrors
          ?.find(({ field }) => field.toLowerCase() === fieldName.toLowerCase());
      }

      if (violation) {
        return this.createError({
          message: violation.message.replace(`[${value}]: `, ''),
        });
      }

      return true;
    },
  );
}

addMethod(string, 'requestFieldValidation', requestValidationHandler);

export function attachFieldErrors(
  apiResponse,
  fieldPrefix = '',
) {
  if (apiResponse?.xhr?.fieldErrors) {
    this.fieldErrors = [
      ...this.fieldErrors,
      ...apiResponse.xhr.fieldErrors.map(({ field, message }) => ({
        field: fieldPrefix + (field.includes('.') ? field.split('.')[1] : field),
        message,
      })),
    ];
  }
}

export default string;
