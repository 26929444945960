// Business rules for exceptions
// https://imagitas.atlassian.net/wiki/spaces/MGODev/pages/573276161/MGO+Address+Type+Mover+Type+Business+Rules

export default {
  data() {
    return {
      oldAddressError: '',
      newAddressError: '',
    };
  },
  watch: {
    oldAddressString(newValue, oldValue) {
      if (oldValue.length && newValue !== oldValue && this.oldAddressError) {
        this.oldAddressError = '';
      }
    },
    newAddressString(newValue, oldValue) {
      if (oldValue.length && newValue !== oldValue && this.newAddressError) {
        this.newAddressError = '';
      }
    },
  },
  computed: {
    oldAddressString() {
      const {
        oldStreetAddress,
        oldCity,
        oldState,
        oldZipCode,
        oldUrbanizationName,
        oldPoBoxNumber,
      } = this;
      return [
        oldStreetAddress,
        oldCity,
        oldState,
        oldZipCode,
        oldUrbanizationName,
        oldPoBoxNumber,
      ].join('');
    },
    newAddressString() {
      const {
        newStreetAddress,
        newCity,
        newState,
        newZipCode,
        newUrbanizationName,
        newPrivateMailbox,
        newPoBoxNumber,
      } = this;
      return [
        newStreetAddress,
        newCity,
        newState,
        newZipCode,
        newUrbanizationName,
        newPrivateMailbox,
        newPoBoxNumber,
      ].join('');
    },
    oldPoBoxException() {
      return (
        [
          'R777AddressException',
          'PoBoxRequiredException',
          'InvalidPrimaryPoBoxException',
        ].indexOf(this.oldAddressError) >= 0
        || (!this.oldStreetHasPoBox && this.oldAddressError === 'OldPoBoxRequiredException')
      );
    },
    oldStreetHasPoBox() {
      return this.includesPoBox(this.oldStreetAddress);
    },
    showOldPoBox() {
      return (
        this.oldPoBoxException
        || (!this.oldStreetHasPoBox && this.oldAddressError === 'OldPoBoxRequiredException')
        || this.oldPoBoxNumber
      );
    },
    newStreetHasPoBox() {
      return this.includesPoBox(this.newStreetAddress);
    },
    newPoBoxException() {
      return (
        [
          'R777AddressException',
          'PoBoxRequiredException',
        ].indexOf(this.newAddressError) >= 0
        || ([
          'InvalidPrimaryPoBoxException',
        ].indexOf(this.newAddressError) >= 0 && !this.newStreetHasPoBox)
        || (!this.newStreetHasPoBox && this.newAddressError === 'NewPoBoxRequiredException')
      );
    },
    showNewPoBox() {
      return this.newPoBoxException
        || (!this.newStreetHasPoBox && this.newAddressError === 'NewPoBoxRequiredException')
        || this.newPoBoxNumber;
    },
    showNewPrivateMailbox() {
      return this.newPrivateMailbox
      || [
        'CCR1CmraAddressException',
        'CmraAddressException',
        'PrivateMailboxRequiredException',
      ].indexOf(this.newAddressError) >= 0;
    },
  },
  methods: {
    includesPoBox(testStr) {
      const regex = /^(po)\s?(box)\s?[a-zA-Z0-9]{1,10}$/i;
      return regex.test(testStr);
    },
  },
};
