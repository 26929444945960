/* eslint-disable func-names, max-len, no-template-curly-in-string, prefer-arrow-callback */
import {
  addDays,
  startOfDay,
  subDays,
  parseISO,
  format,
} from 'date-fns';
import string from './stringValidator';

export const startDate = string()
  .transform(function (_, originalValue) {
    return originalValue ? originalValue.replace(/-/g, '') : '';
  })
  .label('Start Forwarding Date')
  .test(
    'min',
    'The Date to Begin Forwarding Mail cannot be more than 30 days earlier than today.',
    function (value) {
      const today = startOfDay(new Date());

      return value >= format(subDays(today, 30), 'yyyyMMdd');
    },
  )
  .test(
    'max',
    'The Date to Begin Forwarding Mail cannot be more than 90 days in the future.',
    function (value) {
      const today = startOfDay(new Date());

      return value <= format(addDays(today, 90), 'yyyyMMdd');
    },
  )
  .required('The Date to Begin Forwarding Mail should not be blank.');

export const bufferedStartDate = string()
  .transform(function (_, originalValue) {
    return originalValue ? originalValue.replace(/-/g, '') : '';
  })
  .label('Mail Forwarding Date')
  .test(
    'min',
    'The Mail Forwarding Date must be no sooner than 7 days from today.',
    function (value) {
      const today = startOfDay(new Date());
      const { moverType } = this.options.context;

      if (moverType === 'BUSINESS') return true;
      return value >= format(addDays(today, 7), 'yyyyMMdd');
    },
  )
  .test(
    'min',
    'The Mail Forwarding Date must be no sooner than 7 days from today.',
    function (value) {
      const today = startOfDay(new Date());
      const { moverType } = this.options.context;

      if (moverType !== 'BUSINESS') return true;
      return value >= format(addDays(today, 7), 'yyyyMMdd');
    },
  )
  .test(
    'max',
    'The Mail Forwarding Date cannot be more than 90 days in the future.',
    function (value) {
      const today = startOfDay(new Date());
      return value <= format(addDays(today, 90), 'yyyyMMdd');
    },
  )
  .required('The Mail Forwarding Date should not be blank.');

export const stopDate = string()
  .transform(function (_, originalValue) {
    return originalValue ? originalValue.replace(/-/g, '') : '';
  })
  .label('End Forwarding Date')
  .test(
    'min',
    'The Date to Stop Forwarding Mail cannot be less than 7 days later than today.',
    function (value) {
      const today = startOfDay(new Date());

      return value >= format(addDays(today, 7), 'yyyyMMdd');
    },
  )
  .test(
    'min 15 day forwarding period',
    'The Date to Stop Forwarding Mail cannot be less than 15 days after the Date to Begin Forwarding Mail.',
    function (value) {
      const today = startOfDay(new Date());
      const parsedStartDate = this.options.context.startDate
        ? parseISO(this.options.context.startDate)
        : null;
      const fifteenDaysAfterStartDate = format(addDays(parsedStartDate || today, 15), 'yyyyMMdd');

      return value >= fifteenDaysAfterStartDate;
    },
  )
  .test(
    'max date',
    'Temporary Mail Forwarding cannot exceed 185 days in duration.',
    function (value) {
      const today = startOfDay(new Date());
      const parsedValue = parseISO(value);
      const parsedStartDate = this.options.context.startDate && parseISO(this.options.context.startDate);

      return parsedValue <= addDays(parsedStartDate || today, 185);
    },
  )
  .required('The Date to Stop Forwarding Mail should be entered for a temporary move.');

export const moverType = string()
  .label('Mover Type')
  .transform(function (_, value) { return value || ''; })
  .matches(
    /(individual|family|business)/gi,
    { excludeEmptyString: true },
  )
  .test(
    'MilitaryAddressException',
    'The move type of Business is not acceptable for this address.',
    function (value) {
      if (value === 'BUSINESS' && this.options?.context?.oldAddressError === 'MilitaryAddressException') return false;

      return true;
    },
  )
  .required('Please select a type of move.');

export const forwardType = string()
  .label('Forward Type')
  .transform(function (_, value) { return value || ''; })
  .matches(
    /(permanent|temporary)/gi,
    { excludeEmptyString: true },
  )
  .required('Please select Permanent or Temporary.');

export const billingType = string()
  .label('Billing Address Type')
  .matches(
    /(old|new|other)/gi,
    { excludeEmptyString: true },
  )
  .required('Please select a Billing Address.');

export const familySameLastName = string()
  .required('This field cannot be empty');

export const familyRemaining = string()
  .required('This field cannot be empty');

export const businessWholeCompany = string()
  .required('This field cannot be empty');

export const multipleAddresses = string()
  .nullable().required('This field cannot be empty');

export const businessName = string()
  .transform(function (_, value) { return value || ''; })
  .required('This field cannot be empty')
  .requestFieldValidation('businessName')
  .matches(/^[0-9a-zA-Z]([a-zA-Z0-9 \-.&])+?$/gi, {
    message: 'The Business Name field allows only letters, numbers and the following characters (- & .).',
    excludeEmptyString: true,
  })

  .max(40, 'The Business Name field only allows ${max} characters. Please shorten and select next.')
  /* min length 2 chars - cant use the .min method */
  .matches(/[a-zA-Z0-9\-.&\s]{2,}/i, {
    message: 'The Business Name field must be at least 2 characters long.',
    excludeEmptyString: true,
  });

const businessAlias = string()
  .transform(function (_, value) { return value || ''; })
  .max(40, 'The ${path} field only allows ${max} characters. Please shorten and select next.')

  .matches(/^[0-9a-zA-Z]([a-zA-Z0-9 \-.&])+?$/gi, {
    message: 'The ${path} field allows only letters, numbers and the following characters(- & .).',
    excludeEmptyString: true,
  })

  .matches(/[a-zA-Z0-9\-.&\s]{2,}/i, {
    message: 'The ${path} field must be at least 2 characters long.',
    excludeEmptyString: true,
  })

  .test(
    'cannotMatchBusinessName',
    'The ${path} field cannot match the Business Name field.',
    function (value) {
      return !value || value?.toLowerCase() !== this.options.context.businessName?.toLowerCase();
    },
  );

const defaultBusinessAlias = businessAlias
  .clone()
  .test(
    'businessAliasCannotMatch',
    'Business aliases cannot match.',
    function (value) {
      if (!value) return true;
      /**
       * take field we're evaluating
       * return true if it matches once,
       * false if it matches more than once
      */
      return ![
        'businessAlias1',
        'businessAlias2',
        'businessAlias3',
        'businessAlias4',
      ].reduce((acc, alias) => {
        let aliasValue = '';
        // alias value should only be set on a value of a context
        if (this?.options?.context && this.options.context[alias]) {
          aliasValue = this.options.context[alias].toLowerCase();
        }

        if (aliasValue === value.toLowerCase()) {
          return acc + 1;
        }
        return acc;
      }, -1);
    },
  );

export const businessAlias1 = defaultBusinessAlias
  .clone()
  .requestFieldValidation('aliases')
  .label('Business Alias 1');

export const businessAlias2 = defaultBusinessAlias
  .clone()
  .requestFieldValidation('aliases')
  .label('Business Alias 2');

export const businessAlias3 = defaultBusinessAlias
  .clone()
  .requestFieldValidation('aliases')
  .label('Business Alias 3');

export const businessAlias4 = defaultBusinessAlias
  .clone()
  .requestFieldValidation('aliases')
  .label('Business Alias 4');


export const multiBusinessAlias = businessAlias
  .clone()
  .requestFieldValidation('aliases')
  .label('Business Alias 4').test(
    'businessAliasCannotMatch',
    'Business aliases cannot match.',
    function (value) {
      if (!value) return true;
      /**
       * take field we're evaluating
       * return true if it matches once,
       * false if it matches more than once
      */
      const thisRef = this;
      return !this.options.context.businessAliases.reduce((acc, alias, index) => {
        let aliasValue = '';
        // alias value should only be set on a value of a context
        if (thisRef?.options?.context && thisRef.options.context?.businessAliases
          && thisRef.options.context?.businessAliases[index]) {
          aliasValue = thisRef.options.context?.businessAliases[index];
        }
        if (aliasValue?.value?.toLowerCase() === value.toLowerCase()) {
          return acc + 1;
        }
        return acc;
      }, -1);
    },
  );
