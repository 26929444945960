import {
  addDays,
  differenceInDays,
  isBefore,
  isEqual,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns';

export default {
  computed: {
    bufferStartDate() {
      const { sdbuf } = this.$route.query;

      // Rehydrate buffer datepicker when returning from payment
      if (sdbuf && this.billingAddressType === 'OTHER') return true;

      const startDate = typeof this.startDate === 'string'
        ? parseISO(this.startDate)
        : this.startDate;
      const minBufferedStartDate = typeof this.minBufferedStartDate === 'string'
        ? parseISO(this.minBufferedStartDate)
        : this.minBufferedStartDate;
      const isBeforeMinBufferDate = isBefore(startDate, minBufferedStartDate)
        || isEqual(startDate, minBufferedStartDate);
      return (
        this.billingAddressType === 'OTHER'
        && isBeforeMinBufferDate
      );
    },
    minBufferedStartDate() {
      if (this.moverType === 'BUSINESS' && this.thirdAddressEligible) {
        return addDays(this.today, 30);
      }
      return addDays(this.today, 7);
    },
    today() {
      return startOfDay(new Date());
    },
    minStartDate() {
      return subDays(this.today, 30);
    },
    maxStartDate() {
      return addDays(this.today, 90);
    },
    // MGO uses stopDate
    minStopDate() {
      const parsedStartDate = this.startDate ? parseISO(this.startDate) : null;
      const sevenDaysFuture = addDays(this.today, 7);
      if (
        parsedStartDate
        && isBefore(parsedStartDate, this.today)
        && Math.abs(differenceInDays(parsedStartDate, sevenDaysFuture)) < 15
      ) {
        return addDays(parsedStartDate, 15);
      }
      if (parsedStartDate && isBefore(parsedStartDate, this.today)) {
        return addDays(this.today, 7);
      }
      return parsedStartDate ? addDays(parsedStartDate, 15) : addDays(this.today, 7);
    },
    maxStopDate() {
      const parsedStartDate = this.startDate ? parseISO(this.startDate) : null;
      return addDays(parsedStartDate || this.today, 185);
    },
    // COAC uses endDate
    // TODO: coordinate with BE to refactor COAC api properties to be consistent with MGO
    minEndDate() {
      const parsedStartDate = this.startDate ? parseISO(this.startDate) : null;
      const sevenDaysFuture = addDays(this.today, 7);
      if (
        parsedStartDate
        && isBefore(parsedStartDate, this.today)
        && Math.abs(differenceInDays(parsedStartDate, sevenDaysFuture)) < 15
      ) {
        return addDays(parsedStartDate, 15);
      }
      if (parsedStartDate && isBefore(parsedStartDate, this.today)) {
        return addDays(this.today, 7);
      }
      return parsedStartDate ? addDays(parsedStartDate, 15) : addDays(this.today, 7);
    },
    maxEndDate() {
      const parsedStartDate = this.startDate ? parseISO(this.startDate) : null;
      return addDays(parsedStartDate || this.today, 185);
    },
  },
};
