import { track } from '../../analytics/events';

export const modalAnalytics = () => ({
  // FORM EVENTS
  form: {
    submitted: () => track('formSubmitted', {
      formType: 'ACTIVATION',
      formName: 'ACTIVATION',
    }),
  },
});
