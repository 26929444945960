/* eslint-disable no-console */
import {
  format,
  isPast,
  parseISO,
} from 'date-fns';
import { object } from 'yup';
import {
  mapErrors,
  objectIsValid,
} from '@mgo/core/src/validations/utils';
import validators from './validations';

export default {
  data() {
    return {
      loading: true,
      lazy: true,

      fieldErrors: [],

      // coa
      coa: {},
      businessName: '',
      businessAlias1: '',
      businessAlias2: '',
      businessAlias3: '',
      businessAlias4: '',
      coaSubmitDate: '',
      informedDelivery: false,
      startDate: '',
      initialStartDate: '',
      endDate: '',
      initialEndDate: '',
      processedOnDate: '',
      moverType: '',
      allowMoverTypeEdit: false,
      forwardType: '',
      allowForwardTypeEdit: false,
      billingAddressType: '',

      // mover
      email: '',
      firstName: '',
      middleName: '',
      lastName: '',
      phoneNumber: '',
      initialPhoneNumber: '', // for offline COA validation
      phoneType: 'MOBILE',

      // old address
      oldStreetAddress: '',
      oldCity: '',
      oldState: '',
      oldZip5: '',
      oldZip9: '',
      oldZipCode: '', // decide if we're going to set default as zip5
      oldUrbanizationName: '',
      oldPoBox: false,
      oldPoBoxNumber: '',
      oldMilitary: false,

      // new address
      newStreetAddress: '',
      newCity: '',
      newState: '',
      newZip5: '',
      newZip9: '',
      newZipCode: '',
      newUrbanizationName: '',
      newPrivateMailbox: '',
      newPoBox: false,
      newPoBoxNumber: '',
      newMilitary: false,
    };
  },
  watch: {
    // Po box exceptions are reset on street address change
    // since a user can either use the street address field
    // or the po box field to satisfy API address validation
    oldStreetAddress() {
      this.oldAddressError = '';
    },
    newStreetAddress() {
      this.newAddressError = '';
    },
  },
  computed: {
    informedDeliveryEligible() {
      return this.moverType !== 'BUSINESS'
        && this.forwardType === 'PERMANENT'
        && !this.coa?.informedDelivery
        && (this.billingAddressType === 'OLD'
          || this.billingAddressType === 'NEW');
    },
    formatProcessedOnDate() {
      if (this.processedOnDate) {
        return format(parseISO(this.processedOnDate, 'yyyy-MM-dd', new Date()), 'MMMM dd, yyyy');
      }
      return '';
    },
    fullName() {
      return `${this.firstName} ${this.middleName || ''} ${this.lastName}`.toUpperCase();
    },
    rules() {
      return object().shape(validators);
    },
    errors() {
      const fieldsToValidate = [
        'email',
        'phoneNumber',
        'phoneType',
        'oldZipCode',
        'oldCity',
        'oldState',
        'oldStreetAddress',
        'newZipCode',
        'newCity',
        'newState',
        'newStreetAddress',
      ];

      if (this.allowMoverTypeEdit) {
        fieldsToValidate.push('moverType');
      }

      if (this.allowForwardTypeEdit) {
        fieldsToValidate.push('forwardType');
      }

      if (this.startDateEditable) {
        fieldsToValidate.push('startDate');
      }

      if (this.endDateEditable) {
        fieldsToValidate.push('endDate');
      }

      if (this.showOldPoBox) {
        fieldsToValidate.push('oldPoBoxNumber');
      }

      if (this.oldState === 'PR') {
        fieldsToValidate.push('oldUrbanizationName');
      }

      if (this.showNewPoBox) {
        fieldsToValidate.push('newPoBoxNumber');
      }

      if (this.newState === 'PR') {
        fieldsToValidate.push('newUrbanizationName');
      }

      if (this.showNewPrivateMailbox) {
        fieldsToValidate.push('newPrivateMailbox');
      }

      return mapErrors(this, fieldsToValidate);
    },
    formIsValid() {
      return objectIsValid(this.errors);
    },
    formattedStartDate() {
      if (!this.initialStartDate) return '';
      return format(parseISO(this.initialStartDate), 'EEEE, MMMM d, yyyy');
    },
    formattedEndDate() {
      if (!this.initialEndDate) return '';
      return format(parseISO(this.initialEndDate), 'EEEE, MMMM d, yyyy');
    },
    startDateEditable() {
      if (!this.initialStartDate) return false;
      return !isPast(parseISO(this.initialStartDate));
    },
    endDateEditable() {
      if (!this.initialEndDate) return false;
      return !isPast(parseISO(this.initialEndDate));
    },
    flagEditable() {
      return !!this.coa?.editable;
    },
  },
  async mounted() {
    this.$store.commit('SET_API_ERROR', {
      apiException: '',
      apiMessage: '',
    });
    await this.hydrateCOA();
    if (!this.flagEditable) {
      this.$router.replace({ name: 'review' });
    }
    this.loading = false;
  },
  methods: {
    async validate() {
      this.oldAddressError = '';
      this.newAddressError = '';
      this.lazy = false;
      await this.$nextTick();

      if (!this.formIsValid) {
        this.scrollToFirstError();
        return;
      }

      this.submit();
    },
    async scrollToFirstError() {
      const errorHTML = document.querySelector('.errors');
      if (errorHTML !== null) {
        setTimeout(() => {
          errorHTML.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    },
    async hydrateCOA() {
      const {
        mover,
        oldAddress,
        newAddress,
        ...coa
      } = await this.$coac.search() || {};

      // general hydration for primitive types
      if (coa) {
        this.coa = coa;
        this.billingAddressType = coa.billingAddressType;
        this.businessName = coa.businessName;
        this.coaSubmitDate = coa.coaSubmitDate;
        this.forwardType = coa.forwardType;
        this.moverType = coa.moverType;
        this.startDate = coa.startDate;
        this.initialStartDate = coa.startDate;
        this.endDate = coa.endDate;
        this.initialEndDate = coa.endDate;
        this.processedOnDate = coa.processedOnDate;
        this.allowMoverTypeEdit = this.moverType === 'FAMILY';
        this.allowForwardTypeEdit = this.forwardType === 'TEMPORARY';
      }

      // hydrate mover
      if (mover) {
        this.email = mover.email;
        this.firstName = mover.firstName;
        this.lastName = mover.lastName;
        this.phoneNumber = mover.mobilePhone || mover.homePhone;
        this.initialPhoneNumber = mover.mobilePhone || mover.homePhone;
        this.phoneType = mover.mobilePhone ? 'MOBILE' : 'HOME';
        this.middleName = mover.middleName;
      }

      // hydrate old address
      if (oldAddress) {
        this.oldStreetAddress = oldAddress.streetAddress;
        this.oldCity = oldAddress.city;
        this.oldState = oldAddress.state;
        this.oldZip5 = oldAddress.zip5;
        this.oldZip9 = oldAddress.zip9;
        this.oldZipCode = oldAddress.zip5;
        this.oldUrbanizationName = oldAddress.urbanizationName;
        this.oldPoBox = oldAddress.poBox;
        this.oldPoBoxNumber = oldAddress.poBoxNumber;
        this.oldMilitary = oldAddress.military;
      }

      // hydrate new address
      if (newAddress) {
        this.newStreetAddress = newAddress.streetAddress;
        this.newCity = newAddress.city;
        this.newState = newAddress.state;
        this.newZip5 = newAddress.zip5;
        this.newZip9 = newAddress.zip9;
        this.newZipCode = newAddress.zip5;
        this.newUrbanizationName = newAddress.urbanizationName;
        this.newPoBox = newAddress.poBox;
        this.newPoBoxNumber = newAddress.poBoxNumber;
        this.newMilitary = newAddress.military;
      }
    },
    async submit() {
      this.loading = true;

      const {
        amsCorrections,
        newAddress,
        medUpdated,
        xhr,
      } = await this.$coac.save({
        coacInformedDeliveryOptIn: this.informedDeliveryEligible
          ? this.informedDelivery
          : false,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        homePhone: (this.phoneType === 'HOME' && this.phoneNumber)
          ? this.phoneNumber
          : null,
        mobilePhone: (this.phoneType === 'MOBILE' && this.phoneNumber)
          ? this.phoneNumber
          : null,
        moverType: this.moverType,
        forwardType: this.forwardType,
        startDate: this.startDate,
        endDate: this.endDate,
        oldAddress: {
          city: this.oldCity,
          state: this.oldState,
          streetAddress: this.oldStreetAddress,
          urbanizationName: this.oldUrbanizationName,
          zipCode: this.oldZipCode, // should we be using zip5 or 9?
          poBoxNumber: this.oldPoBoxNumber || null,
        },
        newAddress: {
          city: this.newCity,
          state: this.newState,
          streetAddress: this.newStreetAddress,
          urbanizationName: this.newUrbanizationName || null,
          zipCode: this.newZipCode, // should we be using zip5 or 9?
          poBoxNumber: this.newPoBoxNumber || null,
          privateMailbox: this.newPrivateMailbox || null,
        },
      });

      sessionStorage.setItem('medUpdated', (medUpdated === undefined) ? false : medUpdated);

      this.loading = false;
      if (xhr?.error) {
        let addressType = xhr?.addressType;
        if (addressType == null && xhr?.exception === 'InvalidChangeException') {
          addressType = 'OLD';
        } else if (addressType == null) {
          addressType = 'NEW';
        }

        if (xhr?.status === 422) this.logException(xhr?.exception);

        this.handleApiError({
          addressType,
          exception: xhr?.exception,
          message: xhr?.message,
        });
      } else if (amsCorrections?.newAddressZipCode && newAddress?.zip5) {
        this.$coac.loginZip = newAddress.zip5;
        this.$router.replace({ name: 'review', query: { amsCorrectedNewZip: true, savedChanges: true } });
      } else if (xhr?.fieldErrors && xhr?.fieldErrors.length > 0) {
        this.errors[`${xhr?.fieldErrors[0].field}`] = { ...xhr?.fieldErrors[0], hasError: true };
        await this.$nextTick();
        this.scrollToFirstError();
      } else {
        this.$coac.loginZip = newAddress.zip5;
        this.$router.replace({ name: 'review', query: { savedChanges: true } });
      }
    },
    // addressType doesn't come back on all error responses
    // be careful if evaluating based on addressType
    async handleApiError({ addressType, exception, message }) {
      if (exception === 'CmraAddressException' && addressType === 'OLD') {
        this.$store.commit('SET_API_ERROR', {
          apiException: exception,
          apiMessage: message || '',
        });
        this.$router.replace({ name: 'review', query: { cmra: true } });
      } else if (exception === 'AddressException' && addressType === 'NEW') {
        this.$store.commit('SET_API_ERROR', {
          apiException: exception,
          apiMessage: message || '',
        });
        this.$router.replace({ name: 'review' });
      } else if (exception === 'NoChangesDetectedException'
        || exception === 'TooManyModsException') {
        this.$store.commit('SET_API_ERROR', {
          apiException: exception,
          apiMessage: message || '',
        });
        this.$router.replace({ name: 'review', query: { exception } });
      } else if (exception === 'PrivateMailboxRequiredException'
        || exception === 'InvalidSecondaryInformationException') {
        this[`${addressType?.toLowerCase()}AddressError`] = exception;
        await this.$nextTick();
        this.scrollToFirstError();
      } else if (exception === 'OldAndNewAddressMatchException') {
        this.oldAddressError = exception;
        this.newAddressError = exception;
        await this.$nextTick();
        this.scrollToFirstError();
      } else if (exception && addressType) {
        // store error exception for use in COAC validations file
        // and computed props/watchers
        this[`${addressType.toLowerCase()}AddressError`] = exception;
        await this.$nextTick();
        this.scrollToFirstError();
      }
    },
    async logException(exception) {
      await this.$coac.logExceptions({
        exception,
        oldAddress: {
          city: this.oldCity,
          state: this.oldState,
          streetAddress: this.oldStreetAddress,
          zipCode: this.oldZipCode,
        },
        newAddress: {
          city: this.newCity,
          state: this.newState,
          streetAddress: this.newStreetAddress,
          zipCode: this.newZipCode,
        },
        zip5: this.newZip5,
        startDate: this.startDate,
        stopDate: this.endDate,
      });
    },
  },
};
