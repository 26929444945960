import { track } from '../../analytics/events';

export const desktopAnalytics = () => ({
  // CLICK EVENTS
  click: {
    editMyOrder: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Edit My Order',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    cancelMyOrder: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Cancel My Order',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    extendMailForwarding: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Extend Mail Forwarding',
      },
      actionOutcome: 'INTERNALLINK',
    }),
  },
});

export const mobileAnalytics = () => ({
  // CLICK EVENTS
  click: {
    editMyOrder: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Edit My Order',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    cancelMyOrder: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Cancel My Order',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    extendMailForwarding: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Extend Mail Forwarding',
      },
      actionOutcome: 'INTERNALLINK',
    }),
  },
});
